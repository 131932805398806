<template>
	<div :class="[labelPosition]">
		<el-form v-if="!mysuccessDialogVisible" :model="form" ref="ruleForm" :rules="rules" :label-position="labelPosition">
			<el-form-item label="客户简介" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入客户简介"
					v-model="form.recommendedzReason">
				</el-input>
			</el-form-item>
			<el-form-item label="客户需求" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入客户需求"
					v-model="form.clientRequirement">
				</el-input>
			</el-form-item>
			<el-form-item label="注意事项" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入注意事项"
					v-model="form.noticeMatter">
				</el-input>
			</el-form-item>
			<el-form-item label="咨询问题" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入咨询问题"
					v-model="form.askQuestions">
				</el-input>
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入备注"
					v-model="form.remark">
				</el-input>
			</el-form-item>
			<el-form-item label="附件" :label-width="formLabelWidth">
				<el-upload :headers="{
					token: $store.state.userInfo.token,
				}" :before-upload="beforeUploadImage" class="upload-demo" name="files"
					:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=client`" :on-success="(file, fileList) =>
						handleChange(
							file,
							fileList,
							'inquiryAttachment',
							'form'
						)
						" :on-remove="(file, fileList, index) =>
		handleRemove(
			file,
			fileList,
			index,
			'inquiryAttachment',
			'form'
		)
		" :on-preview="handelOnChange" multiple :limit="3" :file-list="form.inquiryAttachment">
					<el-button size="small" type="primary">点击上传</el-button>
				</el-upload>
			</el-form-item>

			<el-form-item v-if="false" prop="recommendReason" label="推荐理由" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入内容"
					v-model="form.recommendReason">
				</el-input>
			</el-form-item>
			<el-form-item prop="consultKeyPoint" v-if="false" label="咨询要点" :label-width="formLabelWidth">
				<el-input type="textarea" maxlength="2000" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入内容"
					v-model="form.consultKeyPoint">
				</el-input>
			</el-form-item>
			<el-form-item label="选择行长" prop="selectedPresidentIds" :label-width="formLabelWidth">
				<el-select :popper-append-to-body="false" :popper-options="{ modifiers: { flip: { enabled: true } } }"
					ref="selectLabel" style="width: 100%" filterable :filter-method="dataFilter" v-model="form.selectedPresidentIds"
					multiple placeholder="请选择">
					<el-option v-for="item in options" :key="item.cooperationPresidentId" :value="item.cooperationPresidentId"
						:label="item.cooperationPresidentName">
						<div style="display: flex;justify-content: space-between;">
							<span>{{ item.cooperationPresidentName }}</span>
							<span>{{ item.networkSimpleName }}</span>
							<span style="color: #8492a6; font-size: 13px">{{
								item.shortName
							}}</span>
						</div>
					</el-option>
				</el-select>
			</el-form-item>

			<div v-if="labelPosition == 'top'" style="justify-content: center; display: flex" class="dialog-footer">
				<el-button @click="getrequirementMappingPresident" type="primary">确 定</el-button>
				<el-button @click="back">返 回</el-button>
			</div>
		</el-form>
		<div class="boxs" v-else>
			<div style="display: flex;justify-content: center;align-items: center;padding: 10px;">
				<i style="color: #07c120;font-size: 25px;margin-right: 5px;"
					class="el-icon-success"></i><span>转发成功,行长微信接收情况见下表</span>
			</div>
			<el-table class="tables" :data="tableData" style="width: 100%">
				<el-table-column prop="cooperationPresidentName" label="行长名称">
				</el-table-column>
				<el-table-column label="微信推送状态">
					<template slot-scope="scope">
						{{ scope.row.pushState == 1 ? "推送失败" : "推送成功" }}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="copy(scope.row.shortLink)">复制链接</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div v-if="labelPosition == 'top'" style="justify-content: center; display: flex" class="dialog-footer">
				<el-button @click="back" type="primary">返
					回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getNotRecommendPresidentList4Requirement,
	requirementMappingPresident,
} from '@/api/index.js';
export default {
	name: 'transferForm',
	props: {
		labelPosition: {
			type: String,
			default: 'top',
		},
		requirementId: {},
		recommendReason: {},
		checkedTitleField: {},
		inquiryAttachment: {},
		recommendedzReason: {},
		clientRequirement: {},
		noticeMatter: {},
		askQuestions: {},
		remark: {},
		consultKeyPoint: {},
	},
	data() {
		return {
			mysuccessDialogVisible: false,
			rules: {
				recommendReason: [
					{
						required: true,
						message: '请输入推荐理由',
						trigger: 'blur',
					},
				],
				consultKeyPoint: [
					{
						required: true,
						message: '请输入咨询要点',
						trigger: 'blur',
					},
				],
				selectedPresidentIds: [
					{ required: true, message: '请选择行长', trigger: 'blur' },
				],
			},
			form: {
				recommendReason:
					this.recommendReason || this.$route.query.recommendReason,
				selectedPresidentIds: '',
				checkedTitleField:
					this.checkedTitleField ||
					this.$route.query.checkedTitleField,
				requirementId:
					this.requirementId || this.$route.query.requirementId,
				consultKeyPoint:
					this.consultKeyPoint || this.$route.query.consultKeyPoint,
				inquiryAttachment: this.inquiryAttachment || [],
				recommendedzReason: this.recommendedzReason || '',
				clientRequirement: this.clientRequirement || '',
				noticeMatter: this.noticeMatter || '',
				askQuestions: this.askQuestions || '',
				remark: this.remark || '',
			},
			options: [],
			optionsCopy: [],
			formLabelWidth: '80px',
			value: '',
			tableData: [],
			popperOptions: {
				placement: 'top'
			}
		};
	},
	methods: {
		back() {
			this.mysuccessDialogVisible = false; this.$router.back()
		},
		//点击某个摁扭执行事件 
		copy(data) {
			let url = data;
			//新建一个文本框
			let oInput = document.createElement('input');
			//赋值给文本框
			oInput.value = url;
			document.body.appendChild(oInput);
			// 选择对象;
			oInput.select();
			// 执行浏览器复制命令
			document.execCommand("Copy");
			//复制完成删除掉输入框
			oInput.remove()
			this.$message.success(`复制成功!`);

		},
		beforeUploadImage(file) {
			const isSize = file.size / 1024 / 1024; // 文件大小
			if (isSize > 10) {
				this.$message.error(`上传图片大小不能超过10MB!`);
				return false;
			}
			return true;
		},
		handelOnChange(res) {
			if (res.filePath) {
				res.url = res.filePath
			}
			if (res.url.includes('png') || res.url.includes('jpg') || res.url.includes('bmp') || res.url.includes('jpeg') || res.url.includes('xlsx') || res.url.includes('docx') || res.url.includes('pdf')) {
				let routeData = this.$router.resolve({ path: '/public', query: { url: res.url } });

				window.open(routeData.href, '_blank');
				return;
			}

			location.href =
				this.$baseUrl +
				'/api/file/fileDownloadCommon.do?path=' +
				res.url;
		},
		handleChange(file, fileList, name, obj) {
			this[obj][name].push({
				name: fileList.response.list[0].fileName,
				url: fileList.response.list[0].filePath,
			});
		},
		handleRemove(file, fileList, index, name, obj) {
			this[obj][name].splice(index, 1);
		},
		dataFilter(val) {
			this.value = String(val);
			if (val) {
				//val存在
				this.options = this.optionsCopy.filter(
					(item) =>
						String(item.cooperationPresidentName).includes(val) ||
						String(item.networkSimpleName).includes(val) ||
						String(item.shortName).includes(val)
				);
			} else {
				//val为空时，还原数组
				this.options = this.optionsCopy;
			}
		},
		async getNotRecommendPresidentList4RequirementFN() {
			const res = await getNotRecommendPresidentList4Requirement({
				requirementId:
					this.requirementId || this.$route.query.requirementId,
				presidentStatus: 1,
				pageNo: 1,
				pageSize: 999,
			});
			this.options = res.list;
			this.optionsCopy = res.list;
		},
		getrequirementMappingPresident() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					let form = JSON.parse(JSON.stringify(this.form));

					form.inquiryAttachment = JSON.stringify(
						this.form.inquiryAttachment.map((item) => ({
							fileName: item.name,
							filePath: item.url,
						}))
					);

					form.selectedPresidentIds =
						form.selectedPresidentIds.join();
					if (typeof form.checkedTitleField != 'string') {
						form.checkedTitleField = form.checkedTitleField.join();
					}
					const res = await requirementMappingPresident(form);
					if (res.returncode != 0) {
						this.$confirm('转交失败', '系统提示', {
							confirmButtonText: '确定',
							type: 'warning',
							showCancelButton: false,
							center: true,
						});
					} else {
						this.tableData = res.rtnDataList
						this.$emit('success');
						this.mysuccessDialogVisible = true
						return
						// this.$confirm(
						// 	'转交成功，已转交给,' +
						// 		this.$refs.selectLabel.selected
						// 			.map((v) => v.label)
						// 			.join(),
						// 	'系统提示',
						// 	{
						// 		confirmButtonText: '确定',
						// 		type: 'success',
						// 		showCancelButton: false,
						// 		center: true,
						// 	}
						// );
					}
					this.form.selectedPresidentIds = '';
					this.$emit('closeVisible');
				} else {
					return false;
				}
			});
		},
	},

	created() {
		this.getNotRecommendPresidentList4RequirementFN();
		this.form.recommendReason =
			this.recommendReason || this.$route.query.recommendReason;
		if (this.$route.query.financeRequirementList) {
			const financeRequirementList = JSON.parse(
				this.$route.query.financeRequirementList
			);

			if (financeRequirementList.inquiryAttachment) {
				this.form.inquiryAttachment = JSON.parse(
					financeRequirementList.inquiryAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				this.form.inquiryAttachment = [];
			}
			this.form.recommendedzReason =
				financeRequirementList.recommendedzReason || '';
			this.form.clientRequirement =
				financeRequirementList.clientRequirement || '';
			this.form.noticeMatter = financeRequirementList.noticeMatter || '';
			this.form.askQuestions = financeRequirementList.askQuestions || '';
			this.form.remark = financeRequirementList.remark || '';
		}
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-select-dropdown {
	top: 35px !important;
	position: absolute !important;
}

::v-deep .el-select-dropdown,
::v-deep .el-picker-panel {
	top: -18px !important;
	transform: translateY(-100%);
}

::v-deep .popper__arrow {
	display: none;
}

.top {
	width: 100%;
	box-sizing: border-box;
	padding: 10px 20px;
	background: #f0f2f5 url(~@/assets/background.svg);
}

::v-deep .top .el-form-item {
	margin-bottom: 10px;
}

.top {
	min-height: 100vh;
}

.top .tables {
	margin-top: 100px;
	margin-bottom: 25px;
}
</style>
